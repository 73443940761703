import { React, useRef, useState, useEffect } from "react";

import logo from "../Images/logo.webp";

import "./new-register.css";
import "bootstrap/dist/css/bootstrap.min.css";
import secureLocalStorage from "react-secure-storage";

import RegisterService from "../services/register.service";

import { useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";

// import ReCAPTCHA from "react-google-recaptcha";

import $ from "jquery";

import { toast } from "react-toastify";

function RegisterForm(props) {
  const TITLE = process.env.REACT_APP_API_SITE_TITLE + " | Registration Form";
  const [fields, setFields] = useState({
    name: "",
    lname: "",
    phone: "",
    emailid: "",
    store_location: "",
    sales_name_f: "",
    sales_name_l: "",
    question: "",
    question2: "",
    // captcha: "",
  });
  const [loading, setLoading] = useState(false);

  const [errors, setErrors] = useState({});
  // const [captcha, setCaptcha] = useState(false);

  const [locations, setLocations] = useState([]);

  const [question] = useState([
    {
      value: "Furniture",
      label: "Furniture",
    },
    {
      value: "Mattress",
      label: "Mattress",
    },
    {
      value: "Both",
      label: "Both",
    },
  ]);

  const [question2] = useState([
    {
      value: "30 days",
      label: "30 days",
    },
    {
      value: "60 days",
      label: "60 days",
    },
    {
      value: "90 days",
      label: "90 days",
    },
  ]);

  const [signoutTime, setSignoutTime] = useState(900000);
  const [value_userform, setValue_userform] = useState([]);

  const navigate = useNavigate();

  secureLocalStorage.removeItem("registerform");

  useEffect(() => {
    const getLocations = async () => {
      let errors = {};

      try {
        const response = await RegisterService.getStoreLocation();

        var locations = response.data.data;
        const results = [];
        locations.map((value) => {
          return results.push({
            value: value.id,
            label: value.store_location,
          });
        });
        await setLocations([...results]);

        errors["voucher"] = response.data.response;
      } catch (err) {
        if (err.status === 429 || err.status === "429") {
          alert("Max Limit Reached. Please wait.");
        }
      }
    };
    getLocations();
  }, []);

  const ref = useRef();

  const pasted = (e) => {
    e.preventDefault();
    return false;
  };

  const onlyNumbers = (evt) => {
    const { name, value } = evt.target;
    evt.target.value = evt.target.value.replace(/[^0-9]+/g, "");

    setFields((prevValue) => {
      return {
        ...prevValue,
        [name]: evt.target.value,
      };
    });
  };

  const handleChange = (e) => {
    let { value, name } = e.target;
    if (name === "state" && value === "true") {
      value = "";
    }
    if (name === "store_location" && value === "true") {
      value = "";
    }

    setFields((prevValue) => {
      return {
        ...prevValue,
        [name]: value,
      };
    });
  };
  const saveErrors = (err) => {
    setErrors(err);
  };

  const submituserRegistrationForm = (e) => {
    e.preventDefault();
    let data = {
      fname: fields?.name,
      lname: fields?.lname,
      phone: fields?.phone,
      email: fields?.emailid,
      store_location: fields?.store_location,
      sales_name_f: fields?.sales_name_f,
      sales_name_l: fields?.sales_name_l,
      question: fields?.question,
      question2: fields?.question2,
      // captcha: captcha,
    };

    try {
      setLoading(true);
      const responseuserform = RegisterService.regData(data);
      responseuserform
        .then((response) => {
          var valueUserform = response?.data?.status;
          setValue_userform(valueUserform);
          setLoading(false);

          // toast.success("Registered Successfully!", {
          //   position: "top-center",
          //   autoClose: 1500,
          //   hideProgressBar: true,
          //   closeOnClick: true,
          //   pauseOnHover: true,
          //   draggable: true,
          //   progress: undefined,
          //   theme: "colored",
          // });
          secureLocalStorage.setItem(
            "registerform",
            JSON.stringify({
              name: fields.name,
              lname: fields.lname,
              phone: fields.phone,
              emailid: fields.emailid,
              store_location: fields.store_location,
              sales_name_f: fields.sales_name_f,
              sales_name_l: fields.sales_name_l,
              question: fields.question,
              question2: fields?.question2,

              // captcha: captcha,
            })
          );
          localStorage.removeItem("return_page");
          localStorage.setItem("isRegister", true);

          navigate("/register/registration-thankyou");

          if (value_userform?.includes("redirect")) {
          }
        })
        .catch((err) => {
          setLoading(false);
          console.log(err);
          if (
            err?.response?.data?.message &&
            typeof err?.response?.data?.message === "string"
          ) {
            toast.error(err?.response?.data?.message, {
              position: "top-center",
              autoClose: 3000,
              hideProgressBar: true,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "colored",
            });
          }

          let errorData = {};
          errorData["name"] =
            err?.response?.data?.message &&
            err?.response?.data?.message.fname &&
            err?.response?.data?.message.fname[0];
          errorData["lname"] =
            err?.response?.data?.message &&
            err?.response?.data?.message.lname &&
            err?.response?.data?.message.lname[0];
          errorData["phone"] =
            err?.response?.data?.message &&
            err?.response?.data?.message.phone &&
            err?.response?.data?.message.phone[0];
          errorData["emailid"] =
            err?.response?.data?.message &&
            err?.response?.data?.message.email &&
            err?.response?.data?.message.email[0];
          errorData["store_location"] =
            err?.response?.data?.message &&
            err?.response?.data?.message.store_location &&
            err?.response?.data?.message.store_location[0];
          errorData["sales_name_f"] =
            err?.response?.data?.message &&
            err?.response?.data?.message.sales_name_f &&
            err?.response?.data?.message.sales_name_f[0];
          errorData["sales_name_l"] =
            err?.response?.data?.message &&
            err?.response?.data?.message.sales_name_l &&
            err?.response?.data?.message.sales_name_l[0];
          errorData["question"] =
            err?.response?.data?.message &&
            err?.response?.data?.message.question &&
            err?.response?.data?.message.question[0];
          errorData["question2"] =
            err?.response?.data?.message &&
            err?.response?.data?.message.question2 &&
            err?.response?.data?.message.question2[0];
          errorData["checkederrorcaptcha"] =
            err?.response?.data?.message &&
            err?.response?.data?.message.captcha &&
            err?.response?.data?.message.captcha[0];
          errorData["checkederrortwo"] =
            err?.response?.data?.message &&
            err?.response?.data?.message.size &&
            err?.response?.data?.message.size[0];
          saveErrors(errorData);
          setTimeout(() => {
            if (
              err?.response?.data?.message.store_location &&
              !err?.response?.data?.message.fname &&
              !err?.response?.data?.message.lname &&
              !err?.response?.data?.message.email &&
              !err?.response?.data?.message.phone &&
              !err?.response?.data?.message.sales_name_f &&
              !err?.response?.data?.message.sales_name_l
            ) {
              $("#store_location").focus().select();
            } else if (
              err?.response?.data?.message.question &&
              !err?.response?.data?.message.store_location &&
              !err?.response?.data?.message.fname &&
              !err?.response?.data?.message.lname &&
              !err?.response?.data?.message.email &&
              !err?.response?.data?.message.phone &&
              !err?.response?.data?.message.sales_name_f &&
              !err?.response?.data?.message.sales_name_l
            ) {
              $("#question").focus().select();
            } else {
              ref?.current?.focus();
            }
          }, 200);
        });
    } catch (err) {
      alert(err);
      if (err.response.status === 429 || err.response.status === "429") {
        alert("Max Limit Reached. Please wait.");
      }
      let errorData = {};
      errorData["name"] = err?.response?.data?.message.fname[0];
      errorData["lname"] = err?.response?.data?.message.lname[0];
      errorData["phone"] = err?.response?.data?.message.phone[0];
      errorData["emailid"] = err?.response?.data?.message.emailid[0];
      errorData["store_location"] =
        err?.response?.data?.message.store_location[0];
      errorData["sales_name_f"] = err?.response?.data?.message.sales_name_f[0];
      errorData["sales_name_l"] = err?.response?.data?.message.sales_name_l[0];
      errorData["question"] = err?.response?.data?.message.question[0];
      errorData["question2"] = err?.response?.data?.message.question2[0];
      errorData["checkederrorcaptcha"] =
        err?.response?.data?.message.captcha[0];
      saveErrors(errorData);
    }
  };

  // function onCaptchaChange(value) {
  //   if (value === null) {
  //     setCaptcha(false);
  //   } else {
  //     setCaptcha(true);
  //   }
  // }

  const Select2Field = ({ name, label, options, value }) => {
    const selectRef = useRef();

    useEffect(() => {
      const $select = $(selectRef.current);

      $select.select2();

      $select.on("change", (event) => {
        const selectedValue = $(event.target).val();
        setFields((prevValue) => {
          return {
            ...prevValue,
            [name]: selectedValue,
          };
        });
      });

      return () => {
        $select.select2("destroy");
        $select.off("change");
      };
    }, []);

    return (
      <>
        <select
          ref={selectRef}
          name={name}
          className="form-select register-select2"
          value={value || ""}
          id={name}
        >
          <option value="" disabled={true} selected={true}>
            Select {label}
          </option>
          {options.map((opt, index) => (
            <option key={index} value={opt.value}>
              {opt.label}
            </option>
          ))}
        </select>
      </>
    );
  };

  return (
    <>
      <Helmet>
        <title>{TITLE}</title>
      </Helmet>
      <section className="form-body-center form-section-bg new-register">
        <div className="container">
          <div className="row gy-4 align-items-center">
            <div className="col-12 col-md-12 col-xl-12">
              <div className="register-logo">
                <img
                  src={logo}
                  alt="city-logo.png"
                  className="img-fluid"
                  width="360"
                  height="118"
                />
              </div>
            </div>
            <div className="col-12 col-md-12 col-xl-12">
              <div className="d-flex justify-content-center text-bg-primary top-bg">
                <div className="col-12 col-xl-12 text-center">
                  <h2 className="form-heading">
                    Register your guest for a chance to win!
                    {/* Register your guest for their $25 Mastercard Reward Card! */}
                  </h2>
                </div>
              </div>
            </div>

            <div className="col-12 col-md-12 col-xl-12">
              <div className="card border-0 rounded-4 p-0">
                <div className="card-body p-3 p-md-4 p-xl-5">
                  <form
                    onSubmit={submituserRegistrationForm}
                    name="userRegistrationForm"
                  >
                    <div className="row gy-3 overflow-hidden">
                      <div className="col-md-4 col-sm-12 mb-3">
                        <div className="form-floating ">
                          <i className="zmdi zmdi-account-o icon-position"></i>

                          <input
                            type="text"
                            className="form-control"
                            style={
                              errors.name ? { border: "1px solid red" } : null
                            }
                            name="name"
                            placeholder="Customer First Name"
                            ref={errors.name ? ref : null}
                            value={fields.name || ""}
                            autoFocus=""
                            onChange={handleChange}
                          />

                          <label className="form-label">
                            Customer First Name
                            <span class="gl-form-asterisk"></span>
                          </label>
                        </div>
                        <div className="errorMsg">{errors.name}</div>
                      </div>

                      <div className="col-md-4 col-sm-12 mb-3">
                        <div className="form-floating">
                          <i className="zmdi zmdi-account-o icon-position"></i>

                          <input
                            type="text"
                            className="form-control"
                            name="lname"
                            style={
                              errors.lname && !errors.name
                                ? { border: "1px solid red" }
                                : null
                            }
                            placeholder="Customer Last Name"
                            value={fields.lname || ""}
                            ref={errors.lname && !errors.name ? ref : null}
                            onChange={handleChange}
                          />

                          <label className="form-label">
                            Customer Last Name
                            <span class="gl-form-asterisk"></span>
                          </label>
                        </div>
                        <div className="errorMsg">
                          {errors.lname && !errors.name ? errors.lname : null}
                        </div>
                      </div>
                      <div className="col-md-4 col-sm-12 mb-3">
                        <div className="form-floating ">
                          <i className="zmdi zmdi-local-phone icon-position"></i>

                          <input
                            type="text"
                            name="phone"
                            style={
                              errors.phone && !errors.lname && !errors.name
                                ? { border: "1px solid red" }
                                : null
                            }
                            className="form-control"
                            placeholder="Customer Phone#"
                            value={fields.phone || ""}
                            ref={
                              errors.phone && !errors.lname && !errors.name
                                ? ref
                                : null
                            }
                            onChange={handleChange}
                            onKeyUp={onlyNumbers}
                            onPaste={pasted}
                          />

                          <label className="form-label">
                            Customer Phone #
                            <span class="gl-form-asterisk"></span>
                          </label>
                        </div>
                        <div className="errorMsg">
                          {errors.phone && !errors.lname && !errors.name
                            ? errors.phone
                            : null}
                        </div>
                      </div>

                      <div className="col-md-4 col-sm-12 mb-3">
                        <div className="form-floating ">
                          <i className="zmdi zmdi-email-open icon-position"></i>

                          <input
                            type="text"
                            className="form-control"
                            name="emailid"
                            ref={
                              errors.emailid &&
                              !errors.phone &&
                              !errors.lname &&
                              !errors.name
                                ? ref
                                : null
                            }
                            style={
                              errors.emailid &&
                              !errors.phone &&
                              !errors.lname &&
                              !errors.name
                                ? { border: "1px solid red" }
                                : null
                            }
                            placeholder="Customer Email"
                            value={fields.emailid || ""}
                            onChange={handleChange}
                          />

                          <label className="form-label">
                            Customer Email<span class="gl-form-asterisk"></span>
                          </label>
                        </div>
                        <div className="errorMsg">
                          {errors.emailid &&
                          !errors.phone &&
                          !errors.lname &&
                          !errors.name
                            ? errors.emailid
                            : null}
                        </div>
                      </div>

                      <div className="col-md-4 col-sm-12 mb-3">
                        <div className="form-floating ">
                          <i className="zmdi zmdi-account-circle icon-position"></i>

                          <input
                            type="text"
                            className="form-control"
                            style={
                              errors.sales_name_f &&
                              !errors.emailid &&
                              !errors.phone &&
                              !errors.lname &&
                              !errors.name
                                ? { border: "1px solid red" }
                                : null
                            }
                            name="sales_name_f"
                            placeholder="Employee First Name"
                            ref={
                              errors.sales_name_f &&
                              !errors.emailid &&
                              !errors.phone &&
                              !errors.lname &&
                              !errors.name
                                ? ref
                                : null
                            }
                            value={fields.sales_name_f || ""}
                            onChange={handleChange}
                          />

                          <label className="form-label">
                            Employee First Name
                            <span class="gl-form-asterisk"></span>
                          </label>
                        </div>
                        <div className="errorMsg">
                          {errors.sales_name_f &&
                          !errors.emailid &&
                          !errors.phone &&
                          !errors.lname &&
                          !errors.name
                            ? errors.sales_name_f
                            : null}
                        </div>
                      </div>

                      <div className="col-md-4 col-sm-12 mb-3">
                        <div className="form-floating ">
                          <i className="zmdi zmdi-account-circle icon-position"></i>

                          <input
                            type="text"
                            className="form-control"
                            name="sales_name_l"
                            placeholder="Employee Last Name"
                            style={
                              errors.sales_name_l &&
                              !errors.sales_name_f &&
                              !errors.emailid &&
                              !errors.phone &&
                              !errors.lname &&
                              !errors.name
                                ? { border: "1px solid red" }
                                : null
                            }
                            ref={
                              errors.sales_name_l &&
                              !errors.sales_name_f &&
                              !errors.emailid &&
                              !errors.phone &&
                              !errors.lname &&
                              !errors.name
                                ? ref
                                : null
                            }
                            value={fields.sales_name_l || ""}
                            onChange={handleChange}
                          />

                          <label className="form-label">
                            Employee Last Name
                            <span class="gl-form-asterisk"></span>
                          </label>
                        </div>
                        <div className="errorMsg">
                          {errors.sales_name_l &&
                          !errors.sales_name_f &&
                          !errors.emailid &&
                          !errors.phone &&
                          !errors.lname &&
                          !errors.name
                            ? errors.sales_name_l
                            : null}
                        </div>
                      </div>

                      <div className="col-md-4 col-sm-12 mb-3">
                        <div className="form-floating ">
                          <i className="zmdi zmdi-pin icon-position"></i>

                          <Select2Field
                            name="store_location"
                            label="Location"
                            options={locations}
                            value={fields["store_location"] || ""}
                          />

                          <label className="form-label form-label-select">
                            Store Location<span class="gl-form-asterisk"></span>
                          </label>
                        </div>
                        <div className="errorMsg">
                          {errors.store_location &&
                          !errors.sales_name_l &&
                          !errors.sales_name_f &&
                          !errors.emailid &&
                          !errors.phone &&
                          !errors.lname &&
                          !errors.name
                            ? errors.store_location
                            : null}
                        </div>
                      </div>
                      <div className="col-md-8 col-sm-12 mb-3">
                        <div className="form-floating ">
                          <i className="zmdi zmdi-walk icon-position"></i>

                          {/* <select
                            className="form-select register-select2"
                            name="state"
                            ref={selectRef}
                          >
                            <option value="" disabled={true} selected={true}>
                              Select Option
                            </option>
                            {question.map((val, index) => (
                              <option key={index} value={val.value}>
                                {val.label}
                              </option>
                            ))}
                          </select> */}
                          <Select2Field
                            name="question"
                            label="Option"
                            options={question}
                            value={fields["question"] || ""}
                          />

                          {/* <Select
                            classNamePrefix="form-control"
                            className={`select_1 dropdown form-control ${
                              errors.question &&
                              !errors.store_location &&
                              !errors.sales_name_l &&
                              !errors.sales_name_f &&
                              !errors.emailid &&
                              !errors.phone &&
                              !errors.lname &&
                              !errors.name
                                ? "error-select-search"
                                : ""
                            }`}
                            placeholder="Select Option"
                            options={question}
                            isSearchable={true}
                            isClearable={true}
                            name="question"
                            ref={
                              errors.question &&
                              !errors.store_location &&
                              !errors.sales_name_l &&
                              !errors.sales_name_f &&
                              !errors.emailid &&
                              !errors.phone &&
                              !errors.lname &&
                              !errors.name
                                ? ref
                                : null
                            }
                            onChange={(selectedOption) => {
                              if (selectedOption) {
                                setFields((prevValue) => {
                                  return {
                                    ...prevValue,
                                    ["question"]: selectedOption.value,
                                  };
                                });
                              } else {
                                setFields((prevValue) => {
                                  return {
                                    ...prevValue,
                                    ["question"]: "",
                                  };
                                });
                              }
                            }}
                          /> */}
                          <label className="form-label form-label-select">
                            Was the customer’s visit to Ashley to shop for
                            furniture or mattress or both?
                            <span class="gl-form-asterisk"></span>
                          </label>
                        </div>
                        <div className="errorMsg d-inline">
                          {errors.question &&
                          !errors.store_location &&
                          !errors.sales_name_l &&
                          !errors.sales_name_f &&
                          !errors.emailid &&
                          !errors.phone &&
                          !errors.lname &&
                          !errors.name
                            ? errors.question
                            : null}
                        </div>
                      </div>
                      <div className="col-md-12 col-sm-12 mb-3">
                        <div className="form-floating ">
                          <i className="zmdi zmdi-shopping-cart icon-position"></i>

                          <Select2Field
                            name="question2"
                            label="Option"
                            options={question2}
                            value={fields["question2"] || ""}
                          />

                          <label className="form-label form-label-select">
                            How soon do you plan to purchase a mattress?
                            <span class="gl-form-asterisk"></span>
                          </label>
                        </div>
                        <div className="errorMsg d-inline">
                          {errors.question2 &&
                          !errors.question &&
                          !errors.store_location &&
                          !errors.sales_name_l &&
                          !errors.sales_name_f &&
                          !errors.emailid &&
                          !errors.phone &&
                          !errors.lname &&
                          !errors.name
                            ? errors.question2
                            : null}
                        </div>
                      </div>
                      {/* <div className="col-md-8 col-sm-12 mb-3 mb-md-0 mt-0">
                        <div className="overflow-hider">
                          <ReCAPTCHA
                            sitekey={process.env.REACT_APP_API_GOOGLESITEKEY}
                            onChange={onCaptchaChange}
                          />
                        </div>
                        <div className="errorMsg">
                          {!errors.question2 &&
                          !errors.question &&
                          !errors.store_location &&
                          !errors.sales_name_l &&
                          !errors.sales_name_f &&
                          !errors.emailid &&
                          !errors.phone &&
                          !errors.lname &&
                          !errors.name
                            ? errors.checkederrorcaptcha
                            : null}
                        </div>
                      </div> */}

                      <div className="col-md-4 col-sm-12 mt-0 ms-auto">
                        <div className="d-grid">
                          <button
                            className="btn btn-primary btn-lg "
                            type="submit"
                          >
                            Submit
                          </button>
                        </div>
                      </div>

                      <div className="col-12">
                        <div className="alert alert-info alert-info-custom">
                          Limit 1 per household, while supplies last. Some
                          restrictions apply, see store for detail.
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <div className={`loader ${loading ? "in" : ""}`}>
        <div className="spinner-border main-spin"></div>
      </div>

      {/* <Footer2 /> */}
    </>
  );
}
export default RegisterForm;
