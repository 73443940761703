import React, {
  useEffect,
  useLayoutEffect,
  useReducer,
  useRef,
  useState,
} from "react";
import secureLocalStorage from "react-secure-storage";
import logo from "../Images/logo.webp";
import LoadingBox from "./LoadingBox";
import MessageBox from "./MessageBox";

import "bootstrap/dist/css/bootstrap.min.css";
import { useNavigate } from "react-router-dom";
import Model from "./Model";
import UserformService from "../services/userform.service";

import StorageService from "../services/storage.service";
import $ from "jquery";

import { Helmet } from "react-helmet";
import { toast } from "react-toastify";

const reducer = (state, action) => {
  switch (action.type) {
    case "FETCH_REQUEST":
      return { ...state, loading: true };
    case "FETCH_SUCCESS":
      return { ...state, data: action.payload, loading: false };
    case "FETCH_FAIL":
      return { ...state, error: action.payload, loading: false };
    default:
      return state;
  }
};

function UserForm(props) {
  const TITLE = process.env.REACT_APP_API_SITE_TITLE + " | Customer User Form ";

  const [{ loading, error, data }, dispatch] = useReducer(reducer, {
    data: [],
    loading: true,
    error: "",
  });

  const [fields, setFields] = useState({
    name: "",
    lname: "",
    address: "",
    city: "",
    state: "",
    zipcode: "",
    phonenumber: "",
    phonenumber1: "",
    phonenumber2: "",
    emailid: "",
  });

  const [errors, setErrors] = useState({});
  const [states, setStates] = useState([]);
  const [value_three, setValue_three] = useState([]);
  const [i_agree, setI_agree] = useState(false);
  const [i_agree_five, setI_agree_five] = useState(false);
  const [formSubmit, setFormSubmite] = useState(false);
  const [show, setShow] = useState(false);

  // const [signoutTime, setSignoutTime] = useState(180000);
  const [signoutTime, setSignoutTime] = useState(900000);
  const [popup, setPopup] = useState("true");
  const [selectCard, setSelectCard] = useState("");
  const [valueUserform, setValueUserform] = useState("");
  const navigate = useNavigate();
  const ref = useRef();
  localStorage.setItem("returnpage", true);
  localStorage.setItem("page", "/user-form");
  if (localStorage.getItem("userformopen") === "ture") {
    localStorage.setItem("userform", true);
  }
  const open = localStorage.getItem("userform");
  const Voucher = localStorage.getItem("voucher");

  useEffect(() => {
    async function getStates() {
      let errors = {};

      let bodyFormData = new FormData();
      bodyFormData.append("certificate", localStorage.getItem("voucher"));

      // bodyFormData.append("certificate", StorageService.getVoucher());
      try {
        const response = await UserformService.getStates();

        var states = response.data.data;
        const results = [];
        states.map((value) => {
          return results.push({
            value: value.code,
            label: value.state_name,
          });
        });
        await setStates([...results]);
        // setStates(states);
        if (response.data.status === 429 || response.data.status === "429") {
          alert("Max Limit Reached. Please wait.");
        }

        errors["voucher"] = response.data.response;
      } catch (err) {
        if (err.status === 429 || err.status === "429") {
          alert("Max Limit Reached. Please wait.");
        }
      }
    }

    async function getCards() {
      dispatch({ type: "FETCH_REQUEST" });

      let errors = {};

      let bodyFormData = new FormData();
      bodyFormData.append("certificate", localStorage.getItem("voucher"));
      // bodyFormData.append("certificate", StorageService.getVoucher());

      try {
        const response_sheet = await UserformService.getCards(bodyFormData);
        const value_three = response_sheet.data.data;
        setValue_three(value_three);

        errors["voucher"] = response_sheet.data.response;
        dispatch({ type: "FETCH_SUCCESS", payload: value_three });
      } catch (err) {
        if (err.status === 429 || err.status === "429") {
          alert("Max Limit Reached. Please wait.");
        } else if (err.message.includes("400")) {
          dispatch({
            type: "FETCH_FAIL",
            payload: "Failed to load cards",
          });
        } else {
          dispatch({ type: "FETCH_FAIL", payload: err.message });
        }
      }
    }

    if (Voucher === undefined || Voucher === null) {
      localStorage.setItem("returnpage", false);

      localStorage.setItem("userformopen", false);
      localStorage.setItem("userform", false);
      window.location.replace("/");
    }
    // else if (open === undefined || open === null || open === false) {
    //   localStorage.setItem("returnpage", false);
    //   localStorage.removeItem("page");
    //   localStorage.removeItem("ans1");
    //   localStorage.removeItem("ans2");
    //   localStorage.removeItem("ans3");
    //   localStorage.removeItem("ans4");
    //   localStorage.removeItem("ans5");
    //   localStorage.removeItem("ans6");
    //   localStorage.setItem("userformopen", false);
    //   localStorage.setItem("userform", false);
    //   navigate("/survey-form");
    // }
    else {
      getStates();

      getCards();
      setShow(true);
    }
  }, []);

  useEffect(() => {
    function timeout() {
      setTimeout(function () {
        StorageService.destroyTimeout();
        window.location.replace("/");
        return false;
      }, signoutTime);
    }

    timeout();
  }, [signoutTime]);

  // StorageService.setReturnStatus(true);
  // StorageService.setPage("/UserForm");
  // if (StorageService.getFormStatus() === true) {
  //   StorageService.setFormStatus(true);
  // }

  const pasted = (e) => {
    e.preventDefault();
    return false;
  };

  const onlyNumbers = (evt, nextField) => {
    const { name, value } = evt.target;
    evt.target.value = evt.target.value.replace(/[^0-9]+/g, "");

    setFields((prevValue) => {
      return {
        ...prevValue,
        [name]: evt.target.value,
      };
    });
    if (evt.target.value !== "" && nextField && nextField !== "") {
      moveOnMax(evt, nextField);
    }
  };
  const moveOnMax = (field, nextFieldID) => {
    if (field.target.value.length >= field.target.maxLength) {
      document.getElementById(nextFieldID).focus();
    }
  };

  const handleChange = (e) => {
    let { value, name } = e.target;

    if (name === "state" && value === "true") {
      value = "";
    }
    setFields((prevValue) => {
      return {
        ...prevValue,
        [name]: value,
      };
    });
  };

  const handleChangecheckboxfive = (e, value) => {
    let cardName = e.target.value;
    setSelectCard(cardName);
    setI_agree_five(!i_agree_five);
  };
  const handleChangecheckbox = (e, value) => {
    setI_agree(!i_agree);
  };

  const handleClick = (e) => {
    let errors = {};

    if (i_agree === false) {
      e.preventDefault();
      errors["agree"] = "Please accept terms & condition.";
      setErrors(errors);
    }
  };

  const submituserRegistrationForm = (e) => {
    e.preventDefault();
    if (validate()) {
      let fields = {};
      fields["voucher"] = "";
      fields["name"] = "";
      fields["lname"] = "";
      fields["address"] = "";
      fields["city"] = "";
      fields["state"] = "";
      fields["zipcode"] = "";
      fields["phone"] = "";

      fields["emailid"] = "";
      fields["checkederror"] = "";
      fields["checkederrortwo"] = "";
      fields["checkederrorfive"] = "";
      fields["checkedboxerrornine"] = "";

      setFields({});
      if (fields["name"] === "") {
        if (i_agree) {
          secureLocalStorage.setItem(
            "UserForm",
            JSON.stringify({
              name: fields.name,
              lname: fields.lname,
              address: fields.address,
              city: fields.city,
              state: fields.state,
              zipcode: fields.zipcode,
              phone: fields.phone,
              emailid: fields.emailid,
              card: selectCard,
              popup: popup,
            })
          );
          navigate("/thank-you");
          // props.history.push({
          //   pathname: "/thank-you",
          //   state: {
          //     name: fields.name,
          //     lname: fields.lname,
          //     address: fields.address,
          //     city: fields.city,
          //     state: fields.state,
          //     zipcode: fields.zipcode,
          //     phonenumber: fields.phonenumber,
          //     phonenumber1: fields.phonenumber1,
          //     phonenumber2: fields.phonenumber2,
          //     emailid: fields.emailid,
          //     card: selectCard,
          //     popup: popup,
          //   },
          // });
        }
      }
    }
  };

  const validate = (e) => {
    let isformIsValid = false;
    let data = {
      fname: fields.name,
      lname: fields.lname,
      address: fields.address,
      city: fields.city,
      state: fields.state,
      zip: fields.zipcode,
      phone: fields.phone,
      email: fields.emailid,
      choice: selectCard,
      denomination: StorageService.getDenomination(),
      certificate: StorageService.getVoucher(),
    };

    try {
      setFormSubmite(true);
      const responseuserform = UserformService.userForm(data);
      responseuserform
        .then((response) => {
          isformIsValid = true;
          setValueUserform(response.data.status);

          StorageService.setUserformStorage();
          // localStorage.setItem("abc", JSON.stringify(a));
          // var user = JSON.parse(localStorage.getItem('abc'));
          // user.a
          secureLocalStorage.setItem(
            "UserForm",
            JSON.stringify({
              name: fields.name,
              lname: fields.lname,
              address: fields.address,
              city: fields.city,
              state: fields.state,
              zipcode: fields.zipcode,
              phone: fields.phone,

              emailid: fields.emailid,
              card: selectCard,
              popup: response.data.data.popup,
            })
          );
          setFormSubmite(false);

          navigate("/thank-you");

          if (valueUserform?.includes("redirect")) {
          }
        })
        .catch((err) => {
          if (
            err?.response?.data?.message &&
            typeof err?.response?.data?.message === "string"
          ) {
            toast.error(err?.response?.data?.message, {
              position: "top-center",
              autoClose: 3000,
              hideProgressBar: true,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "colored",
            });
          }
          isformIsValid = false;
          let errorData = {};
          errorData["name"] =
            err.response?.data?.message &&
            err.response?.data?.message?.fname &&
            err.response?.data?.message?.fname[0];
          errorData["lname"] =
            err.response?.data?.message &&
            err.response?.data?.message?.lname &&
            err.response?.data?.message?.lname[0];
          errorData["address"] =
            err.response?.data?.message &&
            err.response?.data?.message?.address &&
            err.response?.data?.message?.address[0];
          errorData["city"] =
            err.response?.data?.message &&
            err.response?.data?.message?.city &&
            err.response?.data?.message?.city[0];
          errorData["state"] =
            err.response?.data?.message &&
            err.response?.data?.message?.state &&
            err.response?.data?.message?.state[0];
          errorData["zipcode"] =
            err.response?.data?.message &&
            err.response?.data?.message?.zip &&
            err.response?.data?.message?.zip[0];
          errorData["phone"] =
            err.response?.data?.message &&
            err.response?.data?.message?.phone &&
            err.response?.data?.message?.phone[0];
          errorData["emailid"] =
            err.response?.data?.message &&
            err.response?.data?.message?.email &&
            err.response?.data?.message?.email[0];
          errorData["checkederrortwo"] =
            err.response?.data?.message &&
            err.response?.data?.message?.size &&
            err.response?.data?.message?.size[0];
          errorData["checkederrorfive"] =
            err.response?.data?.message &&
            err.response?.data?.message?.choice &&
            err.response?.data?.message?.choice[0];
          errorData["checkedboxerrornine"] =
            err.response?.data?.message &&
            err.response?.data?.message?.choice &&
            err.response?.data?.message?.choice[0];
          errorData["certificate"] =
            err.response?.data?.message &&
            err.response?.data?.message?.certificate &&
            err.response?.data?.message?.certificate[0];
          setErrors(errorData);
          setTimeout(() => {
            if (
              err?.response?.data?.message.state &&
              !err?.response?.data?.message.fname &&
              !err?.response?.data?.message.lname &&
              !err?.response?.data?.message.address &&
              !err?.response?.data?.message.city
            ) {
              $("#state").focus().select();
            } else {
              ref?.current?.focus();
            }
          }, 200);
          setFormSubmite(false);
        });
    } catch (err) {
      setFormSubmite(false);

      if (err.response.status === 429 || err.response.status === "429") {
        alert("Max Limit Reached. Please wait.");
      }
      isformIsValid = false;
      let errorData = {};
      errorData["name"] = err.response.data.message.fname[0];
      errorData["lname"] = err.response.data.message.lname[0];
      errorData["address"] = err.response.data.message.address[0];
      errorData["city"] = err.response.data.message.city[0];
      errorData["state"] = err.response.data.message.state[0];
      errorData["zipcode"] = err.response.data.message.zip[0];
      errorData["phone"] = err.response.data.message.phone[0];
      errorData["emailid"] = err.response.data.message.phone[0];
      errorData["checkederrorfive"] = err.response.data.message.choice[0];
      errorData["checkedboxerrornine"] = err.response.data.message.choice[0];
      errorData["certificate"] = err.response.data.message.certificate[0];
      setErrors(errorData);
    }
    return isformIsValid;
  };

  const Select2Field = ({ name, label, options, value }) => {
    const selectRef = useRef();

    useLayoutEffect(() => {
      const $select = $(selectRef.current);

      $select.select2();

      $select.on("change", (event) => {
        const selectedValue = $(event.target).val();
        setFields((prevValue) => {
          return {
            ...prevValue,
            [name]: selectedValue,
          };
        });
      });

      return () => {
        $select.select2("destroy");
        $select.off("change");
      };
    }, []);

    return (
      <>
        <select
          ref={selectRef}
          name={name}
          className="form-select register-select2"
          value={value || ""}
          id={name}
        >
          <option value="" disabled={true} selected={true}>
            Select {label}
          </option>
          {options.map((opt, index) => (
            <option key={index} value={opt.value}>
              {opt.label}
            </option>
          ))}
        </select>
      </>
    );
  };

  return (
    <>
      <Helmet>
        <title>{TITLE}</title>
      </Helmet>

      <section className="form-body-center form-section-bg new-register">
        <div className="container">
          <div className="row gy-4 align-items-center">
            <div className="col-12 col-md-12 col-xl-12">
              <div className="register-logo">
                <img
                  src={logo}
                  alt="city-logo.png"
                  className="img-fluid"
                  width="360"
                  height="118"
                />
              </div>
            </div>

            <div className="col-12 col-md-12 col-xl-12">
              <div className="card border-0 rounded-4 p-0">
                <div className="card-body p-3 p-md-4 p-xl-5">
                  <form
                    onSubmit={submituserRegistrationForm}
                    name="userRegistrationForm"
                  >
                    <div className="row gy-3 overflow-hidden">
                      <div className="col-12 col-xl-12 mt-0 text-center">
                        <h2 className="form-heading">
                          Reward Card Redemption Site
                        </h2>
                        <hr />
                      </div>
                      <div className="col-md-4 col-sm-12 mb-3">
                        <div className="form-floating ">
                          <i className="zmdi zmdi-account-o icon-position"></i>

                          <input
                            type="text"
                            className="form-control"
                            style={
                              errors.name ? { border: "1px solid red" } : null
                            }
                            name="name"
                            placeholder="First Name"
                            ref={errors.name ? ref : null}
                            value={fields.name || ""}
                            autoFocus=""
                            onChange={handleChange}
                          />

                          <label className="form-label">
                            First Name<span class="gl-form-asterisk"></span>
                          </label>
                        </div>
                        <div className="errorMsg">{errors.name}</div>
                      </div>

                      <div className="col-md-4 col-sm-12 mb-3">
                        <div className="form-floating">
                          <i className="zmdi zmdi-account-o icon-position"></i>

                          <input
                            type="text"
                            className="form-control"
                            name="lname"
                            style={
                              errors.lname && !errors.name
                                ? { border: "1px solid red" }
                                : null
                            }
                            placeholder="Last Name"
                            value={fields.lname || ""}
                            ref={errors.lname && !errors.name ? ref : null}
                            onChange={handleChange}
                          />

                          <label className="form-label">
                            Last Name<span class="gl-form-asterisk"></span>
                          </label>
                        </div>
                        <div className="errorMsg">
                          {errors.lname && !errors.name ? errors.lname : null}
                        </div>
                      </div>
                      <div className="col-md-4 col-sm-12 mb-3">
                        <div className="form-floating ">
                          <i className="zmdi zmdi-store icon-position"></i>

                          <input
                            type="text"
                            className="form-control"
                            placeholder="Address"
                            value={fields.address || ""}
                            onChange={handleChange}
                            name="address"
                            style={
                              errors.address && !errors.lname && !errors.name
                                ? { border: "1px solid red" }
                                : null
                            }
                            ref={
                              errors.address && !errors.lname && !errors.name
                                ? ref
                                : null
                            }
                          />

                          <label className="form-label">
                            Address<span class="gl-form-asterisk"></span>
                          </label>
                        </div>
                        <div className="errorMsg">
                          {errors.address && !errors.lname && !errors.name
                            ? errors.address
                            : null}
                        </div>
                      </div>
                      <div className="col-md-4 col-sm-12 mb-3">
                        <div className="form-floating ">
                          <i className="zmdi zmdi-city icon-position"></i>

                          <input
                            type="text"
                            className="form-control"
                            placeholder="City"
                            name="city"
                            style={
                              errors.city &&
                              !errors.address &&
                              !errors.lname &&
                              !errors.name
                                ? { border: "1px solid red" }
                                : null
                            }
                            ref={
                              errors.city &&
                              !errors.address &&
                              !errors.lname &&
                              !errors.name
                                ? ref
                                : null
                            }
                            value={fields.city || ""}
                            onChange={handleChange}
                          />

                          <label className="form-label">
                            City<span class="gl-form-asterisk"></span>{" "}
                          </label>
                        </div>
                        <div className="errorMsg">
                          {errors.city &&
                          !errors.address &&
                          !errors.lname &&
                          !errors.name
                            ? errors.city
                            : null}
                        </div>
                      </div>

                      <div className="col-md-4 col-sm-12 mb-3">
                        <div className="form-floating ">
                          <i className="zmdi zmdi-my-location icon-position"></i>

                          <Select2Field
                            name="state"
                            label="State"
                            options={states}
                            value={fields["state"] || ""}
                          />

                          <label className="form-label form-label-select">
                            State<span class="gl-form-asterisk"></span>
                          </label>
                        </div>
                        <div className="errorMsg">
                          {errors.state &&
                          !errors.city &&
                          !errors.address &&
                          !errors.lname &&
                          !errors.name
                            ? errors.state
                            : null}
                        </div>
                      </div>
                      <div className="col-md-4 col-sm-12 mb-3">
                        <div className="form-floating ">
                          <i className="zmdi zmdi-smartphone-code icon-position"></i>

                          <input
                            type="text"
                            className="form-control"
                            placeholder="Zip Code"
                            style={
                              errors.zipcode &&
                              !errors.state &&
                              !errors.city &&
                              !errors.address &&
                              !errors.lname &&
                              !errors.name
                                ? { border: "1px solid red" }
                                : null
                            }
                            name="zipcode"
                            value={fields.zipcode || ""}
                            ref={
                              errors.zipcode &&
                              !errors.state &&
                              !errors.city &&
                              !errors.address &&
                              !errors.lname &&
                              !errors.name
                                ? ref
                                : null
                            }
                            onChange={handleChange}
                          />

                          <label className="form-label">
                            Zip Code<span class="gl-form-asterisk"></span>
                          </label>
                        </div>
                        <div className="errorMsg">
                          {errors.zipcode &&
                          !errors.state &&
                          !errors.city &&
                          !errors.address &&
                          !errors.lname &&
                          !errors.name
                            ? errors.zipcode
                            : null}
                        </div>
                      </div>
                      <div className="col-md-4 col-sm-12 mb-3">
                        <div className="form-floating ">
                          <i className="zmdi zmdi-local-phone icon-position"></i>

                          <input
                            type="text"
                            name="phone"
                            style={
                              errors.phone &&
                              !errors.state &&
                              !errors.zipcode &&
                              !errors.city &&
                              !errors.address &&
                              !errors.lname &&
                              !errors.name
                                ? { border: "1px solid red" }
                                : null
                            }
                            className="form-control"
                            placeholder="Phone#"
                            value={fields.phone || ""}
                            ref={
                              errors.phone &&
                              !errors.state &&
                              !errors.zipcode &&
                              !errors.city &&
                              !errors.address &&
                              !errors.lname &&
                              !errors.name
                                ? ref
                                : null
                            }
                            onChange={handleChange}
                            onKeyUp={onlyNumbers}
                            onPaste={pasted}
                          />

                          <label className="form-label">
                            Phone #<span class="gl-form-asterisk"></span>
                          </label>
                        </div>
                        <div className="errorMsg">
                          {errors.phone &&
                          !errors.state &&
                          !errors.zipcode &&
                          !errors.city &&
                          !errors.address &&
                          !errors.lname &&
                          !errors.name
                            ? errors.phone
                            : null}
                        </div>
                      </div>

                      <div className="col-md-4 col-sm-12 mb-3">
                        <div className="form-floating ">
                          <i className="zmdi zmdi-email-open icon-position"></i>

                          <input
                            type="text"
                            className="form-control"
                            name="emailid"
                            ref={
                              errors.emailid &&
                              !errors.state &&
                              !errors.phone &&
                              !errors.zipcode &&
                              !errors.city &&
                              !errors.address &&
                              !errors.lname &&
                              !errors.name
                                ? ref
                                : null
                            }
                            style={
                              errors.emailid &&
                              !errors.state &&
                              !errors.phone &&
                              !errors.zipcode &&
                              !errors.city &&
                              !errors.address &&
                              !errors.lname &&
                              !errors.name
                                ? { border: "1px solid red" }
                                : null
                            }
                            placeholder="E-mail"
                            value={fields.emailid || ""}
                            onChange={handleChange}
                          />

                          <label className="form-label">
                            Email<span class="gl-form-asterisk"></span>
                          </label>
                        </div>
                        <div className="errorMsg">
                          {errors.emailid &&
                          !errors.state &&
                          !errors.phone &&
                          !errors.zipcode &&
                          !errors.city &&
                          !errors.address &&
                          !errors.lname &&
                          !errors.name
                            ? errors.emailid
                            : null}
                        </div>
                      </div>
                      <div className="col-md-12  col-sm-12 mb-0 mt-0">
                        {loading ? (
                          <LoadingBox />
                        ) : error ? (
                          <MessageBox variant="danger">{error}</MessageBox>
                        ) : (
                          <>
                            <fieldset
                              className={`checkbox-group ${
                                errors.checkederrorfive ? "danger-error" : ""
                              }`}
                            >
                              <legend className="checkbox-group-legend">
                                Choose your Card
                              </legend>
                              {value_three.map((insdex) => {
                                return (
                                  <div className="card-list" key={insdex.name}>
                                    <label className="checkbox-wrapper">
                                      <input
                                        type="radio"
                                        name="imgname"
                                        id={insdex.name}
                                        value={insdex.name}
                                        onChange={handleChangecheckboxfive}
                                        className="checkbox-input"
                                      />
                                      <span className="checkbox-tile">
                                        <span className="checkbox-icon">
                                          {insdex.image ? (
                                            <img
                                              src={
                                                process.env
                                                  .REACT_APP_API_IMAGES +
                                                insdex.image
                                              }
                                              alt={insdex.image}
                                              className="imgcolor img-fluid"
                                            />
                                          ) : (
                                            <div className="pirds pirds2">
                                              <span className="rdcrdtop">
                                                <table border="0">
                                                  <tr>
                                                    <td>{insdex.name}</td>
                                                  </tr>
                                                </table>
                                              </span>
                                              <span className="rdcrd">
                                                REWARD CARD
                                              </span>
                                            </div>
                                          )}
                                        </span>
                                        <span className="checkbox-label">
                                          {insdex.name}
                                        </span>
                                      </span>
                                    </label>
                                  </div>
                                );
                              })}
                            </fieldset>
                          </>
                        )}
                        <div className="errorMsg">
                          {errors.checkederrorfive}
                        </div>
                      </div>
                      <div className="col-md-8 col-sm-12">
                        <div className="form-check">
                          <input
                            className="form-check-input"
                            type="checkbox"
                            name="checkbox"
                            value={fields.checkbox}
                            onChange={handleChangecheckbox}
                            id="myCheck"
                          />
                          <label className="form-check-label" htmlFor="myCheck">
                            I agree to <Model />
                          </label>
                          <div className="errorMsg">{errors.agree}</div>
                        </div>
                      </div>
                      <div className="col-md-4 col-sm-12 ">
                        <div className="d-grid">
                          <button
                            className="btn btn-primary btn-lg"
                            type="submit"
                            onClick={handleClick}
                          >
                            Submit
                          </button>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <div className={`loader ${formSubmit ? "in" : ""}`}>
        <div className="spinner-border main-spin"></div>
      </div>
      <div className={`fix-white ${show ? "hide" : ""}`}></div>
    </>
  );
}
export default UserForm;
